import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton, Dialog, DialogFooter, DialogType, Icon, Text, Spinner, SpinnerSize, IconButton, TooltipHost, DirectionalHint, TooltipDelay, TextField,
    Button,
} from '@fluentui/react'
import Mission, { ErrorMission } from 'requests/objects/mission'
import Status from 'types/status'
import MissionsHandler from 'requests/handlers/missionsHandler'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import EDiffusionAction from 'types/missions/enums/diffusionAction'
import styles from 'styles/components/pages/missions/pivotDiffusion.module.scss'
import Divider from 'components/visuals/divider'
import Card from 'components/containers/card'
import { Columns } from 'react-bulma-components'
import { isDisabled } from 'components/pages/missions/[id]/pivotDetail'
import User from 'requests/objects/user'
import resetCarret from 'helpers/methods/resetCarret'

/**
 * Diffusion button detail
 * @param {object} props Props
 * @param {Function} props.onClick onClick
 * @param {Status} props.status status
 * @param {string} props.text text
 * @param {string} props.iconName iconName
 * @param {boolean=} props.onAction onAction
 * @param {boolean=} props.disabled disabled
 * @returns {JSX.Element} Returns
 */
function DiffusionButton({
    onClick,
    status = Status.IDLE,
    text = '',
    iconName = '',
    onAction = false,
    disabled = false,
}) {
    return (
        <DefaultButton
            onClick={() => onClick()}
            text={text}
            disabled={status === Status.PENDING || disabled}
            iconProps={{ iconName }}
        >
            {onAction && (
                <Spinner size={SpinnerSize.small} />
            )}
        </DefaultButton>
    )
}

/**
 * Pivot detail
 * @param {object} props Props
 * @param {Mission} props.item item
 * @param {MissionsHandler} props.missionsHandler missionsHandler
 * @param {(item: Partial<Mission>, isIniItemToo?: boolean) => void} props.onChange onChange
 * @param {ErrorMission} props.errorField errorField
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {any} props.match match
 * @param {User} props.me me
 * @returns {JSX.Element} Returns
 */
export default function PivotDiffusion({
    item, missionsHandler, onChange, errorField, isReadOnly, match, me,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [onPublish, setOnPublish] = useState(false)
    const [onRePublish, setOnRePublish] = useState(false)
    const [onDelete, setOnDelete] = useState(false)
    const [onRefresh, setOnRefresh] = useState(false)

    const [isDifussionModalVisible, setIsDifussionModalVisible] = useState(false)

    const [isDiffusingWebsite, setIsDiffusingWebsite] = useState(false)
    const [isRemoveDiffusingWebsite, setIsRemoveDiffusingWebsite] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    /** @type {React.MutableRefObject<RequestApi<Mission>>} */
    const handlerMission = useRef(null)

    const diffuse = useCallback(
        async (actionName = EDiffusionAction.ADD) => {
            // eslint-disable-next-line no-nested-ternary
            const setOnAction = actionName === EDiffusionAction.REPUBLISH ? setOnRePublish
                : actionName === EDiffusionAction.DELETE ? setOnDelete : setOnPublish

            try {
                setOnAction(true)
                setStatus(Status.PENDING)
                handlerMission.current = missionsHandler.diffuse(item, actionName)
                const mission = await handlerMission.current.fetch()
                setOnAction(false)
                setStatus(Status.RESOLVED)
                onChange(mission)
                setIsDifussionModalVisible([EDiffusionAction.ADD, EDiffusionAction.EDIT].includes(actionName))
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setOnAction(false)
                        setStatus(Status.REJECTED)
                        break
                }
            }
        }, [missionsHandler, item, onChange],
    )

    const diffuseWebsite = useCallback(
        async () => {
            try {
                setIsDiffusingWebsite(true)
                setStatus(Status.PENDING)
                handlerMission.current = missionsHandler.diffuseWebsiteInfo(item.missionId)
                const mission = await handlerMission.current.fetch()
                setStatus(Status.RESOLVED)
                setIsDiffusingWebsite(false)
                onChange(mission)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        setIsDiffusingWebsite(false)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setStatus(Status.REJECTED)
                        break
                }
            }
        }, [missionsHandler, item.missionId, onChange],
    )

    useEffect(() => () => {
        handlerMission?.current?.cancel()
    }, [])
    const removeDiffuseWebsite = useCallback(
        async () => {
            try {
                setIsRemoveDiffusingWebsite(true)
                setStatus(Status.PENDING)
                handlerMission.current = missionsHandler.removeDiffuseWebsiteInfo(
                    item.missionId,
                )
                const mission = await handlerMission.current.fetch()
                setStatus(Status.RESOLVED)
                setIsRemoveDiffusingWebsite(false)
                onChange(mission)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        setIsRemoveDiffusingWebsite(false)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setStatus(Status.REJECTED)
                        break
                }
            }
        }, [missionsHandler, item.missionId, onChange],
    )

    const refreshDiffusion = useCallback(
        async () => {
            try {
                setOnRefresh(true)
                setStatus(Status.PENDING)
                handlerMission.current = missionsHandler.refreshDiffusion(item.missionId)
                const mission = await handlerMission.current.fetch()
                onChange(mission)
                setOnRefresh(false)
                setStatus(Status.RESOLVED)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setOnRefresh(false)
                        setStatus(Status.REJECTED)
                        break
                }
            }
        }, [missionsHandler, item, onChange],
    )

    useEffect(() => () => {
        handlerMission?.current?.cancel()
    }, [])

    return (
        <>
            <Card
                title={(
                    <div
                        style={{
                            display: 'inline-flex', justifyContent: 'space-between', width: 'calc(100% - 35px)', alignItems: 'baseline',
                        }}
                    >
                        Informations site vitrine
                        <div style={{ display: 'flex', gap: 5 }}>
                            <DefaultButton
                                onClick={() => diffuseWebsite()}
                                text={item.websiteUrl ? 'Rediffuser' : 'Diffuser'}
                                disabled={status === Status.PENDING || !isReadOnly}
                                iconProps={{ iconName: 'ScreenCast' }}
                            >
                                {isDiffusingWebsite && (
                                    <Spinner size={SpinnerSize.small} />
                                )}
                            </DefaultButton>
                            {item.websiteUrl && (
                                <>
                                    <DefaultButton
                                        onClick={() => setShowDialog(true)}
                                        text="Supprimer"
                                        disabled={status === Status.PENDING || !isReadOnly}
                                        iconProps={{ iconName: 'Delete' }}
                                    >
                                        {isRemoveDiffusingWebsite && <Spinner size={SpinnerSize.small} />}
                                    </DefaultButton>

                                    <Dialog
                                        hidden={!showDialog}
                                        onDismiss={() => setShowDialog(false)}
                                        dialogContentProps={{
                                            type: DialogType.normal,
                                            title: 'Confirmation',
                                            subText: 'Voulez-vous vraiment supprimer cette diffusion ?',
                                        }}
                                    >
                                        <DialogFooter>
                                            <DefaultButton
                                                onClick={() => setShowDialog(false)}
                                                text="Annuler"
                                            />
                                            <DefaultButton
                                                onClick={() => {
                                                    setShowDialog(false)
                                                    removeDiffuseWebsite()
                                                }}
                                                text="Confirmer"
                                            />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}

                        </div>
                    </div>
                )}
                iconName="EventInfo"
            >
                <Columns>
                    <Columns.Column size="half">
                        <TextField
                            label="Présentation DGC et client"
                            value={item.websiteDescription}
                            readOnly={isReadOnly || isDisabled('websiteDescription', me, match)}
                            borderless={isReadOnly || isDisabled('websiteDescription', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteDescription: newVal })}
                            errorMessage={errorField.websiteDescription}
                            multiline
                            required
                            autoAdjustHeight
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label="Intitulé du poste"
                            value={item.websiteName}
                            readOnly={isReadOnly || isDisabled('websiteName', me, match)}
                            borderless={isReadOnly || isDisabled('websiteName', me, match)}
                            onChange={(ev, newVal) => {
                                onChange({ websiteName: newVal?.toUpperCase() })
                                resetCarret(ev)
                            }}
                            errorMessage={errorField.websiteName}
                            multiline
                            required
                            autoAdjustHeight
                            maxLength={100}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="half">
                        <TextField
                            label="Description du poste"
                            value={item.websiteDescriptionPoste}
                            readOnly={isReadOnly || isDisabled('websiteDescriptionPoste', me, match)}
                            borderless={isReadOnly || isDisabled('websiteDescriptionPoste', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteDescriptionPoste: newVal })}
                            errorMessage={errorField.websiteDescriptionPoste}
                            multiline
                            required
                            autoAdjustHeight
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label="Profil recherché"
                            value={item.websiteProfile}
                            readOnly={isReadOnly || isDisabled('websiteProfile', me, match)}
                            borderless={isReadOnly || isDisabled('websiteProfile', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteProfile: newVal })}
                            errorMessage={errorField.websiteProfile}
                            multiline
                            required
                            autoAdjustHeight
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="half">
                        <TextField
                            label="Avantages"
                            value={item.websiteAdvantages}
                            readOnly={isReadOnly || isDisabled('websiteAdvantages', me, match)}
                            borderless={isReadOnly || isDisabled('websiteAdvantages', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteAdvantages: newVal })}
                            errorMessage={errorField.websiteAdvantages}
                            multiline
                            required
                            maxLength={100}
                            autoAdjustHeight
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label="Notre accompagnement"
                            value={item.websiteAccompaniement}
                            readOnly={isReadOnly || isDisabled('websiteAccompaniement', me, match)}
                            borderless={isReadOnly || isDisabled('websiteAccompaniement', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteAccompaniement: newVal })}
                            errorMessage={errorField.websiteAccompaniement}
                            multiline
                            autoAdjustHeight
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="half">
                        <TextField
                            label="Contenu du post LinkedIn"
                            value={item.websiteLinkedinDescription}
                            readOnly={isReadOnly || isDisabled('websiteLinkedinDescription', me, match)}
                            borderless={isReadOnly || isDisabled('websiteLinkedinDescription', me, match)}
                            onChange={(ev, newVal) => onChange({ websiteLinkedinDescription: newVal })}
                            errorMessage={errorField.websiteLinkedinDescription}
                            multiline
                            autoAdjustHeight
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderLabel={(props, defaultRender) => (
                                <div style={{ display: 'flex' }}>
                                    {defaultRender(props)}
                                    <TooltipHost
                                        content="Si laissé vide alors la publication sur LinkedIn ne sera pas créée."
                                        directionalHint={DirectionalHint.topCenter}
                                        delay={TooltipDelay.zero}
                                        styles={{
                                            root: {
                                                display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                            },
                                        }}
                                    >
                                        <Icon
                                            iconName="Info"
                                            styles={{ root: { padding: '3px', cursor: 'help' } }}
                                        />
                                    </TooltipHost>
                                </div>
                            )}
                        />
                    </Columns.Column>
                    <Columns.Column size="half">
                        <TextField
                            label="Url"
                            value={item.websiteUrl || ''}
                            borderless
                            readOnly
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderInput={(props, defaultRender) => (item.websiteUrl
                                ? (
                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                        <a
                                            href={item.websiteUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.websiteUrl}
                                        </a>
                                    </Text>
                                )
                                : defaultRender(props))}
                        />
                    </Columns.Column>
                </Columns>
            </Card>
            <br />
            <div
                className={styles['pivot-diffusion']}
            >
                <header>
                    <div className={styles['header-title-refresh']}>
                        <Text
                            variant="large"
                            block
                        >
                            <Icon iconName="BacklogBoard" />
                            {' '}
                            Diffusions Job Boards
                        </Text>
                        {!onRefresh ? (
                            <TooltipHost
                                content="Rafraîchir les informations des job boards."
                                directionalHint={DirectionalHint.topCenter}
                                delay={TooltipDelay.medium}
                                styles={{
                                    root: {
                                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                    },
                                }}
                            >
                                <IconButton
                                    iconProps={{ iconName: 'Refresh' }}
                                    disabled={
                                        status === Status.PENDING || !item.diffusionAction || item.diffusionAction.value === EDiffusionAction.DELETE
                                    }
                                    onClick={() => refreshDiffusion()}
                                />
                            </TooltipHost>

                        ) : (
                            <Spinner
                                className={styles['refresh-spinner']}
                                size={SpinnerSize.small}
                            />
                        )}

                    </div>
                    <div className={styles['header-action-buttons']}>
                        {!item.diffusionJobBoards?.length ? (
                            <DiffusionButton
                                iconName="ScreenCast"
                                text="Diffuser"
                                onClick={() => diffuse(EDiffusionAction.ADD)}
                                status={status}
                                onAction={onPublish}
                                disabled={!isReadOnly || !item.websiteUrl}
                            />
                        ) : (
                            <>
                                <DiffusionButton
                                    iconName="ScreenCast"
                                    text="Rediffuser"
                                    onClick={() => diffuse(EDiffusionAction.REPUBLISH)}
                                    status={status}
                                    onAction={onRePublish}
                                    disabled={!isReadOnly || !item.websiteUrl}
                                />
                                <DiffusionButton
                                    iconName="Edit"
                                    text="Modifier"
                                    onClick={() => diffuse(EDiffusionAction.EDIT)}
                                    status={status}
                                    onAction={onPublish}
                                    disabled={!isReadOnly || !item.websiteUrl}
                                />
                                <DiffusionButton
                                    iconName="Delete"
                                    text="Supprimer"
                                    onClick={() => diffuse(EDiffusionAction.DELETE)}
                                    status={status}
                                    onAction={onDelete}
                                    disabled={!isReadOnly || !item.websiteUrl}
                                />
                            </>
                        )}
                    </div>
                </header>
                <Divider />
                {/* <PrimaryButton
                        onClick={() => refreshDiffusion()}
                        text="Rafraichir"-
                        disabled={status === Status.PENDING}
                        iconProps={{ iconName: 'Refresh' }}
                    >
                        {onRefresh && (
                            <Spinner size={SpinnerSize.small} />
                        )}
                    </PrimaryButton>
                    <br /> */}
                {item.diffusionJobBoards?.length ? (
                    <ul className={styles['job-boards-container']}>
                        {item.diffusionJobBoards.map(diffusionJobBoard => (
                            <li key={diffusionJobBoard.jobBoardId}>
                                <div className={styles['job-board-card']}>
                                    <header>
                                        <Text
                                            variant="medium"
                                            block
                                        >
                                            <Icon iconName="Communications" />
                                            {' '}
                                            {diffusionJobBoard.jobBoard.name}
                                        </Text>
                                        <Text>{diffusionJobBoard.status}</Text>
                                    </header>
                                    <Divider />
                                    <div className={styles['job-board-reason']}>
                                        <Text>{diffusionJobBoard.reason}</Text>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Text>Aucune diffusion en cours</Text>
                )}
            </div>
            <Dialog
                hidden={!isDifussionModalVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Diffusion',
                    subText: 'Veuillez faire les 3 étapes avant de terminer la diffusion',
                }}
                modalProps={{
                    isBlocking: false,
                    layerProps: {
                        onLayerDidMount: () => /** @type {HTMLElement} */(document.activeElement).blur(),
                    },
                }}
                maxWidth={1000}
                minWidth={1000}
                styles={{ main: { height: 1350 } }}
            >
                <iframe
                    title="myFrame"
                    src={item.diffusionUrl}
                    height={700}
                    width="100%"
                    style={{ maxWidth: 940, maxHeight: 1300 }}
                />
                <DialogFooter>
                    <DiffusionButton
                        iconName="CheckMark"
                        text="Fermer"
                        onClick={() => setIsDifussionModalVisible(false)}
                        status={status}
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}
