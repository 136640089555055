/* eslint-disable max-classes-per-file */
import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects/client'
import User from 'requests/objects/user'

/**
 * Commercial Information Object
 */
export default class CommercialInformation extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.commercialInformationId commercialInformationId
     * @param {number=} data.consultantId consultantId
     * @param {User=} data.consultant consultant
     * @param {number=} data.commercialInformationNatureId commercialInformationNatureId
     * @param {object=} data.commercialInformationNature commercialInformationNature
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {string=} data.prospectName prospectName
     * @param {number=} data.departmentId departmentId
     * @param {object=} data.department department
     * @param {string=} data.rdvDate rdvDate
     * @param {number=} data.candidateId candidateId
     * @param {object=} data.candidate candidate
     * @param {string=} data.comment comment
     * @param {boolean=} data.isProcessed isProcessed
     * @param {boolean=} data.isPublic isPublic
     * @param {boolean=} data.isProspect isProspect
     * @param {number=} data.commercialInformationProcessOptionId commercialInformationProcessOptionId
     * @param {object=} data.commercialInformationProcessOption commercialInformationProcessOption
     * @param {string=} data.contactLastname contactLastname
     * @param {string=} data.contactFirstname contactFirstname
     * @param {string=} data.contactPhone contactPhone
     * @param {string=} data.contactFunction contactFunction
     * @param {string=} data.contactEmail contactEmail
     * @param {boolean=} data.isAccepted contactEmail
     * @param {number=} data.contactSubDivisionId subDivisionId
     * @param {object=} data.contactSubDivision subDivision
     * @param {number=} data.contactMetierId metierId
     * @param {object=} data.contactMetier metier
     * @param {number=} data.contactDivisionId divisionId
     * @param {object=} data.contactDivision division
     */
    constructor({
        commercialInformationId,
        consultantId,
        consultant,
        commercialInformationNatureId,
        commercialInformationNature,
        createdByUserId,
        createdByUser,
        clientId,
        client,
        prospectName = '',
        departmentId,
        department,
        rdvDate,
        candidateId,
        candidate,
        comment = '',
        isProcessed = false,
        isPublic = false,
        isProspect = false,
        commercialInformationProcessOptionId,
        commercialInformationProcessOption,
        contactLastname,
        contactFirstname,
        contactPhone,
        contactFunction = '',
        contactEmail,
        contactSubDivisionId,
        contactSubDivision,
        contactMetierId,
        contactMetier,
        contactDivisionId,
        contactDivision,
        isAccepted = null,
        ...data
    } = {}) {
        super(data)
        this.commercialInformationId = commercialInformationId
        this.consultantId = consultantId
        this.consultant = consultant
        this.commercialInformationNatureId = commercialInformationNatureId
        this.commercialInformationNature = commercialInformationNature
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.prospectName = prospectName
        this.departmentId = departmentId
        this.department = department
        this.rdvDate = rdvDate ? new Date(rdvDate) : undefined
        this.candidateId = candidateId
        this.candidate = candidate
        this.comment = comment
        this.isProcessed = isProcessed
        this.isPublic = isPublic
        this.isProspect = isProspect
        this.commercialInformationProcessOptionId = commercialInformationProcessOptionId
        this.commercialInformationProcessOption = commercialInformationProcessOption
        this.contactLastname = contactLastname
        this.contactFirstname = contactFirstname
        this.contactPhone = contactPhone
        this.contactFunction = contactFunction
        this.contactEmail = contactEmail
        this.isAccepted = isAccepted
        this.contactDivisionId = contactDivisionId
        this.contactDivision = contactDivision
        this.contactSubDivisionId = contactSubDivisionId
        this.contactSubDivision = contactSubDivision
        this.contactMetierId = contactMetierId
        this.contactMetier = contactMetier
    }
}

/**
 * Commercial Information Object used to bind error message
 */
export class ErrorCommercialInformation extends ErrorBase {
    constructor() {
        super()
        this.commercialInformationId = ''
        this.consultantId = ''
        this.consultant = ''
        this.createdByUserId = ''
        this.createdByUser = ''
        this.commercialInformationNatureId = ''
        this.commercialInformationNature = ''
        this.clientId = ''
        this.client = ''
        this.prospectName = ''
        this.departmentId = ''
        this.department = ''
        this.firstname = ''
        this.lastname = ''
        this.activity = ''
        this.phone = ''
        this.email = ''
        this.rdvDate = ''
        this.candidateId = ''
        this.candidate = ''
        this.comment = ''
        this.isCandidateInDb = ''
        this.documentName = ''
        this.document = ''
        this.isProcessed = ''
        this.isPublic = ''
        this.isVisited = ''
        this.isProspect = ''
        this.commercialInformationProcessOptionId = ''
        this.commercialInformationProcessOption = ''
        this.contactLastname = ''
        this.contactFirstname = ''
        this.contactPhone = ''
        this.contactFunction = ''
        this.contactEmail = ''
        this.isAccepted = ''
        this.divisionId = ''
        this.division = ''
        this.subDivisionId = ''
        this.subDivision = ''
        this.metierId = ''
        this.metier = ''
    }
}
